import React from "react";
import LogoAndTardi from "./common/LogoAndTardi";

function Terms() {
    return <div className="row ppc-hero__terms">
        <div className="col-12 d-flex flex-column ppc-background ppc-background__terms justify-content-center align-items-center">
            <div className="container-fluid">
                <LogoAndTardi isCard={false} />
            </div>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="m0 ppc-wishes mb-3">
                        <strong>Terms and Conditions</strong>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <p>
                        1. Members, contractors and associates of the Peercoin Foundation are not eligible to participate.<br />
                        1a. To participate, send a Peercoin transaction of value "0" to the address provided. An OP_RETURN or "Metadata" message of your choice has to be present.<br />
                        1b. The OP_RETURN message will be displayed on the website. We reserve the right to remove offensive, abusive or otherwise inappropriate content.<br />
                        1c. One transaction can only be one ticket. Transactions with multiple outputs to the participation address will only have their first output considered.<br />
                        2. In order to claim your price, you have to be able to sign your shipping address as a message to proof that you control the winning raffle ticket.<br />
                        We suggest taking a look at the "Sign Message" feature of the mobile and web wallet.<br />
                        3. Ten winning tickets will be drawn randomly on the 19th of August 2022 - 18:00 UTC. Winning tickets will be announced on talk.peercoin.net. <br />
                        Further instructions on how to claim a winning ticket will be provided there as well. <br />
                        4. We will share your physical mailing address with our logistics partner DHL. After the parcel has reached its destination, your mailing address will be deleted within 3 days.<br />
                        5. We suggest to use a "fresh" address when participating.<br />
                        6. As referenced to in 1), you will need to retain access to address that participated.
                        Message signing and thus proving your claim will not work without the mentioned access. <br />
                        7. Each address can only win once. Multiple tickets from one address will be considered as one.<br />
                        8. We don't provide any warranty for the gift items.<br />
                        9. Winners have seven days to claim their price. The time begins the moment the winning ticket is announced on talk.peercoin.net.<br />
                        10. The winner / recipient of the parcel is responsible for any arrising customs issues (e.g. possible import fees). The items will be declared accordingly for export. <br />
                        Shipment will take place from inside the European Union.<br />
                        11. All participants residing in countries affected by any sanctions of the European Union are excluded from this raffle, since we are not able to ship to those destinations. <br />See full list here: <a href="https://sanctionsmap.eu/" rel="norefferer">https://sanctionsmap.eu</a><br />
                        12. Unclaimed prices will be entered back into the drawing pool until ten winners in total have successfully claimed their price.<br />
                        13. Parcels that are undeliverable and returned to the sender are considered unclaimed again. The ticket may not win again. Once returned, a parcel will not be sent again.<br />
                        14. Legal recourse is excluded.<br />
                        15. All partipants agree with the aforementioned terms and conditions.<br />
                    </p>
                </div>
            </div>
        </div>
    </div>
}

export default Terms;