import React from 'react'

export default function ButtonRow() {
    return (
        <div className="row justify-content-center align-items-center mt-md-5">
            <div className="col-2-auto">
                <a
                    href="https://play.google.com/store/apps/details?id=com.coinerella.peercoin"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="https://play.google.com/intl/en_us/badges/images/generic/en-play-badge.png"
                        alt="Get it on Google Play"
                        height="80" />
                </a>
            </div>
            <div className="col-2-auto">
                <a
                    href="https://apps.apple.com/us/app/peercoin-wallet/id1571755170?itsct=apps_box_badge&amp;itscg=30200"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1626912000&h=8e86ea0b88a4e8559b76592c43b3fe60"
                        alt="Download on the App Store"
                        height="55" />
                </a>
            </div>
            <div className="col-2-auto">
                <a
                    href="https://f-droid.org/de/packages/com.coinerella.peercoin/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="/assets/img/fdroid.png"
                        alt="Download on Fdroid"
                        height="80" />
                </a>
            </div>
            <div className="col-2-auto text-center ppc-buy">
                <a
                    href="https://wallet.peercoin.net"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="/assets/img/web.svg" alt="shopping cart" width={30} /><strong> Go to Web Wallet</strong>
                </a>
            </div>
            <div className="col-2-auto text-center ml-3 ppc-buy" id='buyButton'>
                <a
                    href="https://buy.peercoin.net"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="/assets/img/shopping-cart.png" alt="shopping cart" width={30} /><strong> Get Peercoin</strong>
                </a>
            </div>
        </div>
    )
}
