import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "./common/loader";
import TimeLine from "./TimeLine";
import Hero from "./Hero";

function MessageBoard() {
    const [winMessages, setWinMessages] = useState([]);
    const [messagesAvailable, setMessagesAvailable] = useState(0);
    const [hasMore, setMore] = useState(true);
    const [currentScroll, setCurrentScroll] = useState(0);

    useEffect(() => {
        fetch('https://core.peercoin.win/total')
            .then(response => response.json())
            .then(data => setMessagesAvailable(data))
            .then(fetchMessages(0));
        // eslint-disable-next-line
    }, []);

    const fetchMessages = async scroll => {
        await fetch(`https://core.peercoin.win/messages/${scroll}`)
            .then(response => response.json())
            .then(data => {
                let mergedData = [];
                const unique = data.filter(element => {
                    const isDuplicate = winMessages.includes(element.transactionId);

                    if (!isDuplicate) {
                        mergedData.push(element);
                        return true;
                    }
                    return false;
                });
                setWinMessages(unique)
            });

        setCurrentScroll(scroll);
    };

    const loadMore = async () => {
        if (currentScroll + 15 > messagesAvailable && messagesAvailable !== 0) {
            setMore(false);
        } else {
            const newScroll = parseInt(currentScroll) + 15;
            await fetchMessages(messagesAvailable === 0 ? "0" : newScroll);
        }
    };


    return <React.Fragment>
        <Hero winMessagesTotal={parseInt(messagesAvailable)} />
        {
            winMessages.length > 0 &&
            <InfiniteScroll
                pageStart={0}
                loadMore={() => loadMore()}
                hasMore={hasMore}
                threshold={500}
                loader={<Loader key={"spinner"} />}
            >
                <TimeLine winMessages={winMessages} />
            </InfiniteScroll>
        }</React.Fragment>
}

export default MessageBoard;