import "./scss/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer";
import React from "react";
import MessageBoard from "./components/MessageBoard";
import MessageCard from "./components/MessageCard";
import Terms from "./components/Terms";

function App() {
  function renderMainWidget() {
    if (window.location.pathname.split('/')[1] === "card") {
      return <MessageCard />
    } else if (window.location.pathname.split('/')[1] === "terms") {
      return <Terms />
    } else {
      return <MessageBoard />;
    }
  }

  return (
    <div className="container-fluid overflow-hidden">
      {renderMainWidget()}
      <Footer />
    </div>
  );
}

export default App;
