import React from "react";
import CountUp from "react-countup";
import ButtonRow from "./ButtonRow";
import LogoAndTardi from "./common/LogoAndTardi";

const winningAddress = 'PWinStuffaGJ2EMcK4LTZ6ULtpmmwAYCHh';

function Hero({ winMessagesTotal }) {
  return (
    <div className="row ppc-hero__messageboard">
      <div className="col-12 d-flex flex-column ppc-background justify-content-center align-items-center">
        <div className="container-fluid">
          <LogoAndTardi isCard={false} />
          <div className="row justify-content-center">
            <div className="col-auto">
              <h1> Peercoin 10th anniversary raffle</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto ppc-present__col">
              <div className="ppc-present__container my-5 m-md-5">
                <img className="ppc-present__image" src="/assets/img/box.png" alt="gift box" />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="mb-5 text-center">
              Win one of these beautiful gift boxes with a cool plush tardigrade, buttons, stickers and a postcard shipped to your home.
            </div>
          </div>
          <div className="row justify-content-center mt-sm-3 mt-md-5">
            <div className="col-auto">
              <h2 className="text-center">How to participate</h2>
              <p className="ppc-paragraph">
                Tell us why you should win with Peercoin’s mobile or web app to get your raffle ticket.
                <br />All messages will appear below.<br />
                This Raffle ends on the 19th of August 2022 - 17:59 UTC.<br /><br />
                Ready? Okay! Send <strong>0 PPC</strong> and your Metadata message to
              </p>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <a
                    href={"https://blockbook.peercoin.net/address/" + winningAddress}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="ppc-addr">{winningAddress}</span>
                  </a>
                </div>
                <div>
                  <span
                    onClick={() =>
                      navigator.clipboard.writeText(
                        winningAddress
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src="/assets/img/copy_icon.svg"
                      height="18px"
                      alt="copy to clipboard"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-5 cold-lg-4 col-xl-3 mt-5 text-center mx-auto ppc-buy">
              <a
                href="/terms"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/img/terms.svg" alt="terms and conditions" width={30} /><strong> See Terms and Conditions</strong>
              </a>
            </div>
          </div>
          <ButtonRow />
          <div className="row justify-content-center mt-5">
            <div className="col-auto">
              <div className="m0 ppc-wishes">
                <strong>Raffle Tickets</strong>
              </div>
            </div>
          </div>
          <div className="row pt-3 pb-3 justify-content-center">
            <div className="col-auto">
              <div className="m0 ppc-wishes">
                <CountUp end={winMessagesTotal} />
              </div>
            </div>
          </div>
          <div className="ppc-arrow_down d-none d-lg-block d-xl-block">
            <a href="#timeline">
              <img src="/assets/img/arrow_down.svg" alt="arrow down" />
            </a>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Hero;
